<template>
    <section>
        <article>
            <v-container>
                <v-row align="center">
                    <v-col cols="12" md="6">
                        <Title title="Quem é Magazine Brindes?" />
                        <p class="text-xl-h6">
                            Com amplo conhecimento técnico e corporativo, a Magazine Brindes vem se destacando no mercado de brindes,
                            adesivos e comunicação visual, sempre olhando para o futuro, que por estar cada vez mais próximo, faz com que
                            acompanhemos de perto essa evolução, para que aliados:

                            <br/><br/>

                            <ul class="first-session-list">
                                <li>
                                    Ao excelente atendimento não deixando o cliente - que para nós é nosso maior patrimônio e merecedor de todo
                                    o nosso respeito - na mão quando mais precisa;
                                </li>
                                <li>
                                    Obrigatoriedade e pontualidade nas entregas;
                                </li>
                                <li>
                                    Acima de tudo, cliente despreocupado depois de um pedido fechado, sabedor que pode confiar na empresa.
                                </li>
                            </ul> 
                        </p>
                    </v-col>
                    <v-col v-if="!$vuetify.breakpoint.mobile" align="right" cols="6">
                        <v-img src="@/assets/illustration01.svg" max-width="80%"/>
                    </v-col>
                </v-row>
            </v-container>
        </article>
        <article class="grey lighten-4">
            <v-container>
                <v-row align="center">
                    <v-col cols="12" md="6">
                        <Title title="Nossa estrutura" />
                        <p class="text-xl-h6">
                            <ul class="second-session-list">
                                <li>
                                    <v-icon class="mr-3" color="primary" large>
                                        fas fa-pencil-ruler
                                    </v-icon>
                                    <p>
                                        Possuímos nosso próprio departamento de criação, que nos permite agilidade
                                        na elaboração de artes finais e fotolitos.
                                    </p>
                                </li>
                                <li>
                                    <v-icon class="mr-3" color="primary" large>
                                        fas fa-hand-holding-usd
                                    </v-icon>
                                    <p>
                                        Investimos sempre em tecnologias de vanguarda, para estarmos sempre
                                        atualizados com o que há de mais moderno e inovador. 
                                    </p>
                                </li>
                                <li>
                                    <v-icon class="mr-3" color="primary" large>
                                        fas fa-dolly
                                    </v-icon>
                                    <p>
                                        Além dos produtos nacionais, trabalhamos com toda linha de importados. Mantemos ótima
                                        relação custo benefício com nossos importadores. 
                                    </p>
                                </li>
                            </ul> 
                        </p>
                    </v-col>
                    <v-col v-if="!$vuetify.breakpoint.mobile" order="first" cols="6">
                        <v-img src="@/assets/illustration02.svg" max-width="80%"/>
                    </v-col>
                </v-row>
            </v-container>
        </article>
        <article class="mb-10 mt-5">
            <v-container>
                <v-row align="center">
                    <v-col cols="12" md="6">
                        <Title title="Por trás dos produtos" />
                        <p class="text-xl-h6" style="max-width: 80%">
                            Temos profissionais altamente capacitados para assessorá-lo na escolha de
                            produtos adequados a cada evento, bem como no desenvolvimento de cada produto,
                            garantindo uma fiel reprodução de cores e logomarcas.
                        </p>
                    </v-col>
                    <v-col v-if="!$vuetify.breakpoint.mobile" cols="6">
                        <v-img src="@/assets/illustration03.svg" max-width="80%"/>
                    </v-col>
                </v-row>
            </v-container>
        </article>
    </section>
</template>

<script>
export default {
    components: {
        Title: () => import('@/components/web/layout/widgets/Title')
    },
    data: () => ({
        routes: [
            { title: 'Sobre Nós', route: '/about' }
        ]
    }),
    mounted() {
        this.$store.commit('setBreadcrumbs', this.routes)
    }
}
</script>

<style lang="scss">
    .first-session-list {
        list-style: disc;
        list-style-position: outside!important;

        & li {
          padding: 5px 0;  
        }
    }
    ul.second-session-list {
        padding-left: 0;
    }
    .second-session-list li {
        display: flex;
        align-items: center;
        padding: 10px 0px;

        & p {
            line-height: 20px;
            margin: 0;
            max-width: 80%;
        }
    }
</style>